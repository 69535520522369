import { CustomItem, CustomKind } from "../typings"

export enum BoostName {
  Bomb = "bomb",
  Rocket = "rocket",
  BallsX2 = "ballsX2",
}

export const boosts: CustomItem[] = [
  {
    name: "bomb",
    price: 300,
    currency: ["balance", "ad"],
  },
  {
    name: "rocket",
    price: 300,
    currency: ["balance", "ad"],
  },
  {
    name: "ballsX2",
    price: 300,
    currency: ["balance", "ad"],
  },
].map((item) => ({
  bought: false,
  quantity: 0,
  disposable: true,
  ...item,
})) as CustomItem[]

export const allCustoms: Record<CustomKind, CustomItem[]> = {
  boosts,
}
