import { Ball, Block } from "../typings"
import {
  ballRadius,
  ballSpeed,
  blockHeight,
  blockWidth,
  bonusBlockRadius,
  epsilon,
  height,
  minVisibleBlocks,
  width,
} from "../const"

export class RandomGenerator {
  private seed: number

  constructor(seed: number) {
    this.seed = seed
  }

  next() {
    // Linear Congruential Generator (LCG) parameters
    this.seed = (this.seed * 9301 + 49297) % 233280
    return this.seed / 233280
  }

  clone() {
    return new RandomGenerator(this.seed)
  }
}

// Helper Functions
export function getRandomNumber(random: RandomGenerator) {
  return random.next() * 2 - 1 // Random number between -1 and 1
}

export function getRandomNegativeNumber(random: RandomGenerator) {
  return -random.next() // Random number between -1 and 0
}

// Function to compare two blocks with floating-point tolerance
export function areBlocksEqual(block1: Block, block2: Block): boolean {
  return (
    Math.abs(block1.x - block2.x) < epsilon &&
    Math.abs(block1.y - block2.y) < epsilon &&
    block1.strength === block2.strength &&
    block1.visible === block2.visible &&
    block1.bonus === block2.bonus
  )
}

// Collision Function
export interface CollisionProps {
  ball: Ball
  block: Block
  scale?: number
}

export const collision = ({ ball, block, scale = 1 }: CollisionProps) => {
  if (!block.visible) return { touched: false }

  // Масштабирование и округление размеров
  const ballRadiusScaled = ballRadius * scale
  const blockWidthScaled = blockWidth * scale
  const blockHeightScaled = blockHeight * scale
  const bonusBlockRadiusScaled = bonusBlockRadius * scale

  // Границы AABB для блока
  const blockLeft = block.x * blockWidthScaled
  const blockRight = blockLeft + blockWidthScaled
  const blockTop = block.y * blockHeightScaled
  const blockBottom = blockTop + blockHeightScaled

  // Границы AABB для шара
  const ballLeft = ball.x - ballRadiusScaled
  const ballRight = ball.x + ballRadiusScaled
  const ballTop = ball.y - ballRadiusScaled
  const ballBottom = ball.y + ballRadiusScaled

  // Проверка на пересечение AABB
  const isIntersecting =
    ballRight > blockLeft &&
    ballLeft < blockRight &&
    ballBottom > blockTop &&
    ballTop < blockBottom

  const ballCenterX = ball.x
  const ballCenterY = ball.y
  const blockCenterX = (block.x * blockWidth + blockWidth / 2) * scale

  const blockCenterY = (block.y * blockHeight + blockHeight / 2) * scale

  if (isIntersecting) {
    if (block.bonus) {
      // Проверка на столкновение для бонусного блока

      const dx = ballCenterX - blockCenterX
      const dy = ballCenterY - blockCenterY
      const distance = Math.sqrt(dx * dx + dy * dy)

      if (distance < ballRadiusScaled + bonusBlockRadiusScaled) {
        block.visible = false
        // console.log(
        //   `Collision with bonus block at (${block.x}, ${block.y}). Block becomes invisible.`
        // )
        return { touched: false, bonusDestroy: true }
      }
    } else {
      // Обработка столкновения и изменение координат шара
      const overlapLeft = ballRight - blockLeft
      const overlapRight = blockRight - ballLeft
      const overlapTop = ballBottom - blockTop
      const overlapBottom = blockBottom - ballTop

      const minOverlap = Math.min(
        overlapLeft,
        overlapRight,
        overlapTop,
        overlapBottom
      )

      if (minOverlap === overlapLeft) {
        ball.x = blockLeft - ballRadiusScaled
        ball.speedX = -Math.abs(ball.speedX)
      } else if (minOverlap === overlapRight) {
        ball.x = blockRight + ballRadiusScaled
        ball.speedX = Math.abs(ball.speedX)
      } else if (minOverlap === overlapTop) {
        ball.y = blockTop - ballRadiusScaled
        ball.speedY = -Math.abs(ball.speedY)
      } else if (minOverlap === overlapBottom) {
        ball.y = blockBottom + ballRadiusScaled
        ball.speedY = Math.abs(ball.speedY)
      }
      block.strength--
      // console.log(`Collision with block at (${block.x}, ${block.y}).`)
      return { touched: true }
    }
  }

  return { touched: false }
}

// Move Ball Function
export function moveBall(
  ballsArray: Ball[],
  blocksArray: Block[],
  frameCount: number,
  scale = 1,
  source: string,
  dropAnimation: (block: Block) => void = () => null,
  animateFragments: (block: Block) => void = () => null,
  drawBlocks: () => void = () => null,
  endFunction: () => void = () => null
) {
  let isRunning = false
  let bonusBallsCollected = 0

  // Обновленный массив шаров
  const updatedBalls = ballsArray.map((ball) => {
    if (frameCount >= ball.launchFrame) {
      isRunning = true
      ball.x += ball.speedX
      ball.y += ball.speedY

      // Обработка коллизий со стенами
      if (ball.x + ballRadius * scale > width * scale) {
        ball.x = width * scale - ballRadius * scale
        ball.speedX *= -1
      } else if (ball.x - ballRadius * scale < 0) {
        ball.x = ballRadius * scale
        ball.speedX *= -1
      }
      if (ball.y - ballRadius * scale < 0) {
        ball.y = ballRadius * scale
        ball.speedY *= -1
      } else if (ball.y + ballRadius * scale > height * scale) {
        ball.y = height * scale - ballRadius * scale
        ball.speedX = 0
        ball.speedY = 0
      }

      blocksArray.forEach((block) => {
        const { touched, bonusDestroy } = collision({
          ball,
          block,
          scale,
        })

        if (touched) {
          if (block.strength <= 0) {
            block.visible = false
            animateFragments(block)
            drawBlocks()
          }
          drawBlocks()
        }

        if (bonusDestroy) {
          block.visible = false
          bonusBallsCollected++
          dropAnimation(block)
          drawBlocks()
        }
      })
    }

    return {
      x: ball.x,
      y: ball.y,
      speedX: ball.speedX,
      speedY: ball.speedY,
      launchFrame: ball.launchFrame,
    }
  })

  if (
    updatedBalls.every(
      (ball) =>
        Math.abs(ball.y - (height * scale - ballRadius * scale)) < epsilon &&
        ball.speedX === 0 &&
        ball.speedY === 0
    )
  ) {
    endFunction()
    isRunning = false
  }
  return { isRunning, bonusBallsCollected, updatedBalls }
}

// Blocks and Ball Result Function
export interface Props {
  balls: number
  blocks: Block[]
  directionX: number
  directionY: number
  initialX: number
  scale?: number
}

export interface BlocksAndBallResult {
  ballsArray: Ball[]
  blocksArray: Block[]
  bonusBallsCollected: number
  endX: number
}

export const blocksAndBallResult = ({
  balls,
  blocks,
  directionX,
  directionY,
  initialX,
}: Props): BlocksAndBallResult => {
  const ballsArray: Ball[] = []
  const framesPerDelay = Math.floor((100 / 1000) * 60) // 100 ms delay, assuming 60 FPS

  for (let i = 0; i < balls; i++) {
    ballsArray[i] = {
      x: initialX,
      y: height - ballRadius - 1,
      speedX: ballSpeed * directionX,
      speedY: ballSpeed * directionY,
      launchFrame: i * framesPerDelay,
    }
  }

  let blocksArray: Block[] = blocks.map((block) => ({ ...block }))
  let isRunning = true

  const maxFrames = 10000
  let frameCount = 0
  let bonusBallsCollectedTotal = 0

  while (isRunning && frameCount < maxFrames) {
    frameCount++
    const result = moveBall(ballsArray, blocksArray, frameCount, 1, "backend")

    bonusBallsCollectedTotal += result.bonusBallsCollected
    isRunning = result.isRunning
  }

  return {
    ballsArray,
    blocksArray,
    bonusBallsCollected: bonusBallsCollectedTotal,
    endX: Math.round(ballsArray[0].x),
  }
}

// Add Bonus Blocks Function
export function addBonusBlocks(
  level: number,
  random: RandomGenerator
): Block[] {
  const blocks: Block[] = []
  let bonusAdded = false
  let numVisibleBlocks = 0

  for (let col = 0; col < 6; col++) {
    const block: Block = {
      x: col,
      y: 0,
      visible: true,
      strength: level,
      bonus: false,
    }

    const isBlockVisible = random.next() < 0.5
    if (!bonusAdded && random.next() < 0.3) {
      // Add a bonus block
      block.bonus = true
      block.strength = 1
      bonusAdded = true
    } else {
      // Set visibility and strength based on randomness
      block.visible = isBlockVisible
      block.strength = isBlockVisible ? level : 0
    }

    if (block.visible) numVisibleBlocks++

    blocks.push(block)
  }

  // Ensure at least one bonus block and minimum visible blocks are added
  if (!bonusAdded || numVisibleBlocks < minVisibleBlocks + 1)
    return addBonusBlocks(level, random)

  return blocks
}
