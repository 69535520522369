import { blockHeight, blockWidth } from "@brick/const"
import { MutableRefObject } from "react"
import * as PIXI from "pixi.js"
import { Block } from "@/types"

interface Props {
  textContainerRef: MutableRefObject<PIXI.Container | null>
  blocks: Block[]
  scale: number
}

export const drawBlockStrength = ({
  textContainerRef,
  blocks,
  scale,
}: Props) => {
  const textContainer = textContainerRef.current
  if (!blocks.length || !textContainer) return

  textContainer.removeChildren() // Очистка текстов

  // Используем стиль для BitmapText (указываем свой bitmap шрифт)

  const textPool: PIXI.BitmapText[] = [] // Пул для переиспользования BitmapText

  blocks.forEach((block) => {
    if (block.visible && !block.bonus) {
      // Используем текст из пула или создаем новый, если пул пуст
      let text: PIXI.BitmapText
      if (textPool.length > 0) {
        text = textPool.pop()! // Берем текст из пула
        text.visible = true // Делаем текст видимым
      } else {
        text = new PIXI.BitmapText("", { fontName: "TitleFont" }) // Создаем новый BitmapText, если пул пуст
        textContainer.addChild(text) // Добавляем текст в контейнер
      }

      // Обновляем текстовое содержимое
      text.text = block.strength.toString()
      text.x =
        block.x * blockWidth * scale + (blockWidth * scale) / 2 - text.width / 2
      text.y =
        block.y * blockHeight * scale +
        (blockHeight * scale) / 2 -
        text.height / 2
    }
  })

  // Убираем неиспользуемые тексты и добавляем их в пул
  textContainer.children.forEach((child) => {
    if (
      child instanceof PIXI.BitmapText &&
      !blocks.some(
        (block) => block.visible && block.strength.toString() === child.text
      )
    ) {
      child.visible = false
      textPool.push(child) // Добавляем только BitmapText в пул
    }
  })
}
