import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { Container, Graphics, ParticleContainer, Stage } from "@pixi/react"
import * as PIXI from "pixi.js"
import { Sprite } from "pixi.js"
import { Ball, Block, Fragment } from "@/types"
import {
  ballRadius,
  ballSpeed,
  blockHeight,
  blockWidth,
  width,
} from "@brick/const"
import { useTheme } from "@/hooks/useTheme"
import { drawAimTarget } from "@/utils/Game/drawAimTarget"
import { drawGameBalls } from "@/utils/Game/drawGameBalls"
import { addGameBlocks } from "@/utils/Game/addGameBlocks"
import { moveGameBalls } from "@/utils/Game/moveGameBalls"
import { generateRandomBlocks } from "./generateRandomBlocks"
import { OuterBonusBlockContainer } from "./GameComponents/BonusBlock"
import { Game } from "@/types/game"
import posthog from "posthog-js"
import { drawBlockStrength } from "@/utils/Game/drawBlockStrength"

interface Props {
  blocks: Block[]
  setBlocks: React.Dispatch<React.SetStateAction<Block[]>>
  ballsLength: number
  ballsOptions: { left: number; pushed: boolean }
  blockGraphicsRef: MutableRefObject<PIXI.Graphics | null>
  blockRowsRef: React.MutableRefObject<number>
  ballRef: MutableRefObject<Ball[]>
  drawBlocks: () => void
  gameModalState: "empty" | "record" | "start"
  gameRestarted: boolean
  gameStarting: boolean
  isShootingRef: MutableRefObject<boolean>
  previousBalls: number
  scale: number
  sendFieldInfo: (block: Block[]) => void
  sendProgressFieldInfo: (directionX: number, directionY: number) => void
  setBallsLength: React.Dispatch<React.SetStateAction<number>>
  setBallsOptions: React.Dispatch<
    React.SetStateAction<{ left: number; pushed: boolean }>
  >
  setPreviousBalls: React.Dispatch<React.SetStateAction<number>>
  setRequestBombOpened: React.Dispatch<React.SetStateAction<boolean>>
  setScore: React.Dispatch<React.SetStateAction<number>>
  setShowEndModal: React.Dispatch<React.SetStateAction<boolean>>
  game: Game
  textContainerRef: MutableRefObject<PIXI.Container | null>
}

const MyPixiComponent: React.FC<Props> = ({
  blocks,
  setBlocks,
  ballRef,
  blockRowsRef,
  setScore,
  setShowEndModal,
  setBallsLength,
  scale,
  setBallsOptions,
  sendProgressFieldInfo,
  ballsOptions,
  gameModalState,
  gameRestarted,
  setRequestBombOpened,
  isShootingRef,
  drawBlocks,
  blockGraphicsRef,
  sendFieldInfo,
  previousBalls,
  setPreviousBalls,
  gameStarting,
  game,
  textContainerRef,
}) => {
  const { theme } = useTheme()

  const stageRef = useRef<Stage>(null)
  const frameCountRef = useRef(0)
  const aimPositionRef = useRef<{ x: number; y: number } | null>(null)
  const aimGraphicsRef = useRef<PIXI.Graphics>(null)
  const direction = useRef({ directionX: 0, directionY: 0 })

  const isDraggingRef = useRef<boolean>(false)
  const ballYStartPosition = width * scale - ballRadius * scale - 1
  const ballContainerRef = useRef<PIXI.ParticleContainer<Sprite> | null>(null)
  const fragments = useRef<Fragment[]>([])

  const ballTexture = useMemo(
    () => PIXI.Texture.from("/images/gameBall.png"),
    []
  )

  // Оптимизированная функция drawAim
  const drawAim = useCallback(
    () =>
      drawAimTarget({
        aimGraphicsRef,
        ballRef,
        isDraggingRef,
        aimPositionRef,
        blocks: blocks,
        scale,
      }),
    [scale, ballRef, blocks, aimPositionRef, blockWidth, blockHeight]
  )

  const handlePointerDown = (event: React.PointerEvent<HTMLCanvasElement>) => {
    if (
      !event ||
      ballsOptions.pushed ||
      gameStarting ||
      isShootingRef.current ||
      !ballRef.current.every((ball) => ball.x === ballRef.current[0].x)
    )
      return
    const rect = event.currentTarget.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top
    aimPositionRef.current = { x, y }
    isDraggingRef.current = true
    drawAim()
  }

  const handlePointerMove = (event: React.PointerEvent<HTMLCanvasElement>) => {
    if (
      !event ||
      !isDraggingRef.current ||
      gameStarting ||
      isShootingRef.current ||
      !ballRef.current.every((ball) => ball.x === ballRef.current[0].x)
    )
      return
    const rect = event.currentTarget.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top
    aimPositionRef.current = { x, y }
    drawAim()
  }

  useEffect(() => {
    drawAim()
  }, [drawAim])

  const drawBalls = useCallback(
    () =>
      drawGameBalls({
        ballContainerRef,
        ballRef,
        ballTexture,
        scale,
        stageRef: stageRef as unknown as MutableRefObject<{
          app: PIXI.Application<PIXI.ICanvas>
        } | null>,
      }),
    [scale, ballRef, ballTexture]
  )

  const drawBlocksText = useCallback(
    () =>
      drawBlockStrength({
        textContainerRef,
        blocks,
        scale,
      }),
    [drawBlocks, blocks]
  )

  const addBlocks = () =>
    addGameBlocks({
      blockRowsRef,
      scale,
      blocks,
      setBlocks,
      drawBlocks,
      isShootingRef,
      setRequestBombOpened,
      sendFieldInfo,
      drawBlocksText,
    })

  const handlePointerUp = (event: React.PointerEvent<HTMLCanvasElement>) => {
    if (
      !event ||
      !isDraggingRef.current ||
      gameStarting ||
      isShootingRef.current ||
      (!ballRef.current.every((ball) => ball.x === ballRef.current[0].x) &&
        !ballRef.current.every((ball) => ball.y === ballYStartPosition))
    )
      return
    const rect = event.currentTarget.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top

    const ball = ballRef.current[0]
    const dx = x - ball.x
    const dy = y - ball.y
    let angle = Math.atan2(dy, dx)
    let degrees = angle * (180 / Math.PI)
    const delay = 100
    const framesPerDelay = Math.floor(delay / (1000 / 60))

    if (degrees < 15 && degrees >= 0) {
      degrees = -15
      angle = degrees * (Math.PI / 180)
    } else if (degrees > -15 && degrees < 0) {
      degrees = -15
      angle = degrees * (Math.PI / 180)
    } else if (degrees > 165) {
      degrees = -165
      angle = degrees * (Math.PI / 180)
    } else if (degrees < -165) {
      degrees = -165
      angle = degrees * (Math.PI / 180)
    } else if (degrees > 14 && degrees < 90) {
      degrees = -degrees
      angle = degrees * (Math.PI / 180)
    } else if (degrees > 89 && degrees < 166) {
      degrees = -degrees
      angle = degrees * (Math.PI / 180)
    }
    const directionXWithoutScale = Math.cos(angle)
    const directionYWithoutScale = Math.sin(angle)
    sendProgressFieldInfo(directionXWithoutScale, directionYWithoutScale)
    direction.current = {
      directionX: directionXWithoutScale,
      directionY: directionYWithoutScale,
    }

    ballRef.current = ballRef.current.map((ball, index) => ({
      ...ball,
      speedX: ballSpeed * direction.current.directionX * scale,
      speedY: ballSpeed * direction.current.directionY * scale,
      launchFrame: index * framesPerDelay,
    }))

    setBallsOptions((prev) => ({ ...prev, pushed: true }))
    isShootingRef.current = true

    isDraggingRef.current = false
    aimPositionRef.current = null
    if (aimGraphicsRef.current) aimGraphicsRef.current.clear()

    frameCountRef.current = 0
    moveGameBalls({
      ballRef,
      blocks: blocks,
      setBallsOptions,
      scale,
      setScore,
      addBlocks,
      drawBalls,
      ballYStartPosition,
      setBallsLength,
      blockGraphicsRef,
      drawBlocks,
      isShootingRef,
      previousBalls,
      setPreviousBalls,
      frameCountRef,
      drawBlocksText,
    }).startAnimation()
  }

  useEffect(() => {
    if (gameModalState === "empty") {
      setBlocks((prev) => (prev = []))
      blockRowsRef.current = 0
      addBlocks()
      drawBalls()
      drawBlocks()
      drawBlocksText()
      posthog.capture("started_game", {
        game_id: game.id,
      })
    } else {
      setBlocks(generateRandomBlocks())
      drawBlocks()
      drawBlocksText()
      ballRef.current = [
        {
          x: (width / 2) * scale,
          y: width * scale - ballRadius * scale - 1,
          speedX: 0,
          speedY: 0,
          launchFrame: 0,
        },
      ]
      setBallsOptions({
        left: (width * scale) / 2,
        pushed: false,
      })
    }
  }, [gameModalState, gameRestarted])

  useEffect(() => {
    drawBlocksText()
  }, [drawBlocksText, drawBlocks])

  useEffect(() => {
    drawBlocks()
    drawBalls()
  }, [drawBlocks, drawBalls])

  return (
    <Stage
      ref={stageRef}
      width={width * scale}
      height={width * scale}
      options={{
        backgroundColor: theme === "light" ? 0xffffff : 0x2d2d2d,
      }}
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      onPointerUp={handlePointerUp}
    >
      <Graphics ref={blockGraphicsRef} />
      <ParticleContainer ref={ballContainerRef} />
      <Container ref={textContainerRef} />
      <OuterBonusBlockContainer blocks={blocks} globalScale={scale} />
      <Graphics ref={aimGraphicsRef} />
    </Stage>
  )
}

export default React.memo(MyPixiComponent)
