import { useTranslation } from "react-i18next"
import { DotLottiePlayer, PlayerEvents } from "@dotlottie/react-player"

import congratsAnimation from "@/assets/animations/congrats.json"
import React, { useState } from "react"
import { useLaunchParams } from "@telegram-apps/sdk-react"
import Loader from "@/components/common/Loader"
import { publicationStoryBonus } from "@brick/common"

interface Props {
  loading: boolean
  motivationText: string
  record: number
  resetGameHandle: () => void
  score: number
  sendStoryGame: () => void
  setGameModalState: React.Dispatch<
    React.SetStateAction<"record" | "start" | "empty">
  >
  storyPaid: boolean
  winAmount: number
}

const GameEndModal = ({
  resetGameHandle,
  score,
  winAmount,
  motivationText,
  storyPaid,
  setGameModalState,
  sendStoryGame,
  loading,
  record,
}: Props) => {
  const launchParams = useLaunchParams()

  const { t } = useTranslation()
  const [played, setPlayed] = useState(true)
  return (
    <div className="dark:bg-modal/80 absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-white/80 text-white backdrop-blur-[2px]">
      <div className="w-[90%] flex-col">
        <div className="dark:bg-sec-bg-color rounded-2xl bg-white p-8">
          {!loading ? (
            <>
              <h2
                className={`${!motivationText && "h-[40px]"}font-sfUiBold mb-4 text-wrap break-words text-center text-4xl text-black dark:text-white`}
              >
                {motivationText}
              </h2>
              <div className="border-text-gray grid grid-cols-2 grid-rows-3 gap-0 rounded-[6px] border border-solid border-opacity-100">
                <h3 className="font-sfUiBold text-gray border-1 border-text-gray border-b border-r pb-2 pl-2 pt-2 text-left text-sm capitalize">
                  {t("gameEndModal.score")}:
                </h3>
                <div className="border-1 border-text-gray flex items-center justify-end gap-x-2 border-b pb-2 pr-2 pt-2">
                  <div className="h-[14px] w-[23px]">
                    <img width={23} height={14} src="/images/brick.png" />
                  </div>
                  <p className="font-sfUiBold text-sm text-black dark:text-white">
                    {score}
                  </p>
                </div>
                <h3 className="font-sfUiBold text-gray border-1 border-text-gray border-b border-r py-2 pl-2 text-left text-sm capitalize">
                  {t("gameEndModal.record")}:
                </h3>
                <div className="border-1 border-text-gray flex items-center justify-end gap-x-2 border-b py-2 pr-2">
                  <div className="h-[14px] w-[23px]">
                    <img width={23} height={14} src="/images/brick.png" />
                  </div>
                  <p className="font-sfUiBold text-sm text-black dark:text-white">
                    {record}
                  </p>
                </div>
                <h3 className="font-sfUiBold text-gray border-1 border-text-gray border-r pb-2 pl-2 pt-2 text-left text-sm capitalize">
                  {t("gameEndModal.win")}:
                </h3>
                <div className="flex items-center justify-end gap-x-2 pb-2 pr-2 pt-2">
                  <div className="h-4 w-4">
                    <img width={16} height={16} src="/svg/coin.svg"></img>
                  </div>
                  <p className="font-sfUiBold text-sm text-black dark:text-white">
                    {winAmount}
                  </p>
                </div>
              </div>
            </>
          ) : (
            <div className="flex h-[230px] items-center">
              <Loader size={60} />
            </div>
          )}
        </div>
        {(launchParams.platform === "ios" ||
          launchParams.platform === "android") && (
          <div className="mt-3 flex">
            <button
              onClick={sendStoryGame}
              className="bg-purple flex w-full items-center justify-center gap-x-2 rounded-full py-3"
            >
              <img width={26} height={26} src="/svg/share.svg" />
              {t("gameEndModal.share")}
              {storyPaid && (
                <div className="flex items-center gap-x-2">
                  +{publicationStoryBonus}
                  <img src="/svg/coin.svg" />
                </div>
              )}
            </button>
          </div>
        )}
        <div className="mt-3 flex items-center gap-x-[8px]">
          <button
            onClick={() => {
              resetGameHandle()
              setGameModalState("record")
            }}
            className="bg-button-color flex w-full items-center justify-center rounded-full py-3"
          >
            <img className="mr-2" src="/svg/menu.svg" />
            {t("gameEndModal.menu")}
          </button>
          <button
            onClick={() => resetGameHandle()}
            className="bg-button-color flex w-full items-center justify-center rounded-full py-3"
          >
            <img className="mr-2" src="/svg/play.svg" />
            {t("gameEndModal.play")}
          </button>
        </div>
      </div>
      {played && (
        <div
          className="fixed bottom-0 left-0 right-0 z-50"
          style={{ pointerEvents: "none" }}
        >
          <DotLottiePlayer
            src={congratsAnimation}
            autoplay
            speed={1}
            onEvent={(event: PlayerEvents) => {
              if (event === PlayerEvents.Complete) setPlayed(false)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default GameEndModal
