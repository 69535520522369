import { blockHeight, blockWidth, bonusBlockRadius } from "@brick/const"
import { MutableRefObject } from "react"
import * as PIXI from "pixi.js"
import { Block } from "@/types"

interface Props {
  blockGraphicsRef: MutableRefObject<PIXI.Graphics | null>
  blocks: Block[]
  maxColor: { r: number; g: number; b: number }
  minColor: { r: number; g: number; b: number }
  bonusBlockColor: number
  scale: number
}

const calculateColor = (
  strength: number,
  minColor: { r: number; g: number; b: number },
  maxColor: { r: number; g: number; b: number }
) => {
  const t = strength > 1 ? (strength - 1) / 2 : 0
  return {
    r: Math.round(minColor.r + (maxColor.r - minColor.r) * t) / 255,
    g: Math.round(minColor.g + (maxColor.g - minColor.g) * t) / 255,
    b: Math.round(minColor.b + (maxColor.b - minColor.b) * t) / 255,
  }
}

export const drawGameBlocks = ({
  blockGraphicsRef,
  blocks,
  minColor,
  maxColor,
  bonusBlockColor,
  scale,
}: Props) => {
  const g = blockGraphicsRef.current
  if (!g || !blocks.length) return

  g.clear()

  blocks.forEach((block) => {
    if (block.visible && !block.bonus) {
      // Получаем цвет блока
      const {
        r,
        g: gColor,
        b,
      } = calculateColor(block.strength, minColor, maxColor)
      // Рисуем блок
      g.beginFill([r, gColor, b])
      g.lineStyle(0)
      g.drawRoundedRect(
        block.x * blockWidth * scale,
        block.y * blockHeight * scale,
        blockWidth * scale,
        blockHeight * scale,
        6 * scale
      )
      g.endFill()
    }
    if (block.visible && block.bonus) {
      const centerX = (block.x * blockWidth + blockWidth / 2) * scale
      const centerY = (block.y * blockHeight + blockHeight / 2) * scale

      // Внутренний круг (не пульсирует)
      g.beginFill(bonusBlockColor)
      g.drawCircle(centerX, centerY, bonusBlockRadius / scale)
      g.endFill()
    }
  })
}
