import { useEffect, useState } from "react"
import { dailyBonusMaxTime, dailyBonusReward } from "@brick/common"
import CommonHeader from "@/components/common/CommonHeader"
import { useTranslation } from "react-i18next"
import { DailyQuest as DailyQuestType } from "@/types/quests"
import { useToken } from "@/hooks/useToken"
import { getDailyBonus, postDailyQuest } from "@/api/quests"
import { useUser } from "@/hooks/useUser"

const DailyQuest = () => {
  const { t } = useTranslation()
  const { gameToken } = useToken()
  const { user, updateUserBalance } = useUser()
  const [bonus, setBonus] = useState<DailyQuestType>({
    coins: 0,
    diff: 0,
  })

  const getDailyQuest = async () => {
    const data = await getDailyBonus({ token: gameToken })

    if (data.code === "success")
      setBonus({ coins: data.data.coins, diff: data.data.diff })
  }

  const getDailyReward = async () => {
    const data = await postDailyQuest({ token: gameToken })
    if (data.code === "success") {
      setBonus({ coins: 0, diff: 0 })
      updateUserBalance(user.id, gameToken)
    }
  }

  useEffect(() => {
    if (gameToken) void getDailyQuest()
  }, [gameToken])

  useEffect(() => {
    const interval = setInterval(() => {
      setBonus((prevBonus) => {
        const newDiff = prevBonus.diff + 60000
        const times = prevBonus.diff / dailyBonusMaxTime
        const coins = times * dailyBonusReward
        const newCoins = newDiff >= dailyBonusMaxTime ? dailyBonusReward : coins
        return { coins: newCoins, diff: newDiff }
      })
    }, 60000)

    return () => clearInterval(interval)
  }, [bonus.coins])

  const timeLeft = dailyBonusMaxTime - bonus.diff
  const hours = Math.floor(timeLeft / 1000 / 60 / 60)
  const minutes = Math.floor((timeLeft / 1000 / 60) % 60)
  const fillPercent = Math.floor((bonus.diff / dailyBonusMaxTime) * 100)

  return (
    <>
      <CommonHeader title={t("questsPage.dailyRewards")} styles="mb-3" />
      <div className="dark:bg-dark-canvas mb-3 flex-col items-center justify-between rounded-xl bg-white px-4 py-3">
        <div className="flex w-full items-center justify-between">
          <div>
            <div className="flex w-full items-center">
              <div className="flex w-full">
                <img src="/svg/coin.svg" width={16} height={16} />
                <p className="ml-1 text-sm font-semibold text-black dark:text-white">
                  {t("questsPage.dailyMining")}
                </p>
              </div>
            </div>
            <span className="text-hint-color text-xs font-normal">
              {Math.round(
                (dailyBonusReward / (dailyBonusMaxTime / 1000 / 60 / 60)) * 100
              ) / 100}
              <span className="ml-1">{t("questsPage.coinHour")}</span>
            </span>
          </div>
          <div className="float-right">
            <button
              className="bg-button-color rounded-full px-4 py-1 text-xs text-white disabled:opacity-50"
              disabled={bonus.coins < 1}
              onClick={() => getDailyReward()}
            >
              {t("questsPage.get")}
            </button>
          </div>
        </div>
        <div className="bg-hint-color/10 text-hint-color relative mt-2 flex h-5 w-full items-center justify-end overflow-hidden rounded-3xl px-2 py-0.5 text-sm">
          <div
            className={`absolute bottom-0 left-0 top-0 min-w-6 shrink-0 rounded-3xl px-2 text-right leading-5 text-white transition-all ${Math.floor(bonus.coins * 100) / 100 >= 1 ? "bg-[#29B780]" : "bg-hint-color"} `}
            style={{ width: `${fillPercent}%` }}
          >
            {Math.round(bonus.coins)}
          </div>
          {t("questsPage.toFill", { hours: hours, minutes: minutes })}
        </div>
      </div>
    </>
  )
}

export default DailyQuest
