import { Block } from "@/types"
import { Game } from "@/types/game"
import { requestHandler } from "@/utils/requestHandler"
import { BoostName } from "@brick/common"

interface PostGameStart {
  gameField: Block[]
  token: string
}

interface PostProgressGame {
  ballsAfter: number
  ballsBefore: number
  directionX: number
  directionY: number
  field: Block[]
  id: string
  initialX: number
  newRow: Block[]
  token: string
}

interface PostEndGame {
  id: string
  token: string
}

export interface EndGameStatusRes {
  game: Game
  motivationText: string
  record: number
  score: number
  storyPaid: boolean
  winAmount: number
}

interface PostStoryGame {
  id: string
  token: string
}

interface GetBoostGame {
  ballsCountAfter: number
  ballsCountBefore: number
  boostName: BoostName
  field: Block[]
  id: string
  newRow: Block[]
  token: string
}

interface StoryGameStatusRes {
  reward: number
  text: string
  url: string
  widget_link:
    | {
        url: string
        name: string
      }
    | undefined
}

export const postGameStart = requestHandler<PostGameStart, Game>((params) =>
  fetch(`${import.meta.env.VITE_API}/data/game/start`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${params.token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      field: params.gameField,
    }),
  })
)

export const postProgressGame = requestHandler<PostProgressGame, Game>(
  (params) =>
    fetch(`${import.meta.env.VITE_API}/data/game/progress/${params.id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${params.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ballsCountAfter: params.ballsAfter,
        ballsCountBefore: params.ballsBefore,
        directionX: params.directionX,
        directionY: params.directionY,
        field: params.field,
        initialX: params.initialX,
        newRow: params.newRow,
      }),
    })
)

export const getBoostGame = requestHandler<GetBoostGame, Game>((params) =>
  fetch(`${import.meta.env.VITE_API}/data/game/boost/${params.id}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${params.token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ballsCountAfter: params.ballsCountAfter,
      ballsCountBefore: params.ballsCountBefore,
      boostName: params.boostName,
      field: params.field,
      newRow: params.newRow,
    }),
  })
)

export const postEndGame = requestHandler<PostEndGame, EndGameStatusRes>(
  (params) =>
    fetch(`${import.meta.env.VITE_API}/data/game/end/${params.id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${params.token}`,
      },
    })
)

export const postStoryGame = requestHandler<PostStoryGame, StoryGameStatusRes>(
  (params) =>
    fetch(`${import.meta.env.VITE_API}/data/game/story/${params.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${params.token}`,
        "Content-Type": "application/json",
      },
    })
)
