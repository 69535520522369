import { Block } from "@/types"
import { blockHeight, blockWidth, bonusBlockRadius } from "@brick/const"
import { Container, Sprite } from "@pixi/react"
import { useEffect, useRef, useState } from "react"
import * as PIXI from "pixi.js"

interface Props {
  blocks: Block[]
  globalScale: number
}

interface OuterBlockProps {
  globalScale: number
  scale: number
  blocks: Block[]
}

export const OuterBonusBlockContainer = ({ globalScale, blocks }: Props) => {
  const [scale, setScale] = useState(1)
  const requestRef = useRef<number>()

  const animate = (time: number) => {
    setScale(1 + Math.sin(time / 200) * 0.1)
    requestRef.current = requestAnimationFrame(animate)
  }

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate)
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current)
      }
    }
  }, [])

  return (
    <OuterBonusBlock scale={scale} globalScale={globalScale} blocks={blocks} />
  )
}

export const OuterBonusBlock = ({
  globalScale,
  scale,
  blocks,
}: OuterBlockProps) => {
  const containerRef = useRef<PIXI.Container>(null)

  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    // Очищаем контейнер
    container.removeChildren()

    blocks.forEach((block, index) => {
      if (block.visible && block.bonus) {
        const sprite = new PIXI.Sprite(
          PIXI.Texture.from("/images/outerBonusBall.png")
        )

        sprite.x = (block.x * blockWidth + blockWidth / 2) * globalScale
        sprite.y = (block.y * blockHeight + blockHeight / 2) * globalScale
        sprite.scale.set(scale)
        sprite.width = bonusBlockRadius * 2.5 * scale
        sprite.height = bonusBlockRadius * 2.5 * scale
        sprite.anchor.set(0.5)
        sprite.tint = 0xdff501

        container.addChild(sprite)
      }
    })
  }, [blocks, scale, globalScale])

  return <Container ref={containerRef} />
}
