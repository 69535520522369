import { Ball } from "@/types"
import { MutableRefObject } from "react"

interface Props {
  ballRef: MutableRefObject<Ball[]>
  drawBalls: () => void
}

export const animateConnection = ({
  ballRef,
  drawBalls,
}: Props): { promise: Promise<void>; cancel: () => void } => {
  let animationId: null | number = null
  let isCancelled = false

  const promise = new Promise<void>((resolve) => {
    const startTime = performance.now()
    const duration = 200 // 200ms
    const targetX = ballRef.current[0].x

    // Сохраняем начальные позиции и создаем копию массива шаров
    const startPositions = ballRef.current.map((ball) => ({
      x: ball.x,
      y: ball.y,
    }))
    const balls = [...ballRef.current]

    function animate(currentTime: number) {
      if (isCancelled) {
        cancelAnimationFrame(animationId!)
        resolve()
        return
      }

      const elapsedTime = currentTime - startTime
      const progress = Math.min(elapsedTime / duration, 1)

      // Вычисляем easeProgress один раз для всех шаров
      const easeProgress = easeInOutQuad(progress)

      for (let i = 1; i < balls.length; i++) {
        const ball = balls[i]
        const startPos = startPositions[i]
        ball.x = startPos.x + (targetX - startPos.x) * easeProgress
      }

      drawBalls()

      if (progress < 1) {
        animationId = requestAnimationFrame(animate)
      } else {
        // Устанавливаем конечные позиции
        for (let i = 1; i < balls.length; i++) {
          balls[i].x = targetX
        }
        drawBalls()
        resolve()
      }
    }

    animationId = requestAnimationFrame(animate)
  })

  const cancel = () => {
    isCancelled = true
    if (animationId !== null) {
      cancelAnimationFrame(animationId)
      animationId = null
    }
  }

  return { promise, cancel }
}

function easeInOutQuad(t: number): number {
  return t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2
}
