import { Block } from "@/types"
import { blockHeight, blockWidth } from "@brick/const"
import * as PIXI from "pixi.js"

interface Props {
  block: Block
  blockGraphicsRef: PIXI.Graphics
  scale: number
}

export function animateFragments({ block, scale, blockGraphicsRef }: Props) {
  const FRAGMENT_COUNT = 25
  const GRAVITY = 0.5
  const HORIZONTAL_SPREAD = 2
  const ANIMATION_DURATION = 2000 // 2 seconds

  const fragmentWidth = (blockWidth * scale) / 5
  const fragmentHeight = (blockHeight * scale) / 5

  const fragments: PIXI.Graphics[] = []
  const speeds: { x: number; y: number }[] = []

  // Create a container for all fragments
  const fragmentContainer = new PIXI.Container()
  blockGraphicsRef.addChild(fragmentContainer)

  for (let i = 0; i < FRAGMENT_COUNT; i++) {
    const fragment = new PIXI.Graphics()
    fragment.beginFill(0xffa078)
    fragment.drawRect(0, 0, fragmentWidth, fragmentHeight)
    fragment.endFill()

    const row = Math.floor(i / 5)
    const col = i % 5

    fragment.x = col * fragmentWidth
    fragment.y = row * fragmentHeight

    fragmentContainer.addChild(fragment)
    fragments.push(fragment)

    speeds.push({
      x: (Math.random() - 0.5) * HORIZONTAL_SPREAD,
      y: -Math.random() * 5,
    })
  }

  fragmentContainer.x = block.x * blockWidth * scale
  fragmentContainer.y = block.y * blockHeight * scale

  let startTime: number | null = null
  let animationFrameId: number | null = null

  function animate(timestamp: number) {
    if (!startTime) startTime = timestamp
    const elapsed = timestamp - startTime

    if (elapsed > ANIMATION_DURATION) {
      blockGraphicsRef.removeChild(fragmentContainer)
      return
    }

    fragments.forEach((fragment, index) => {
      fragment.x += speeds[index].x
      fragment.y += speeds[index].y
      speeds[index].y += GRAVITY

      // Add rotation for more dynamic effect
    })

    animationFrameId = requestAnimationFrame(animate)
  }

  animationFrameId = requestAnimationFrame(animate)

  // Return a function to cancel the animation if needed
  return () => {
    if (animationFrameId !== null) {
      cancelAnimationFrame(animationFrameId)
      animationFrameId = null
    }
    if (fragmentContainer.parent) {
      fragmentContainer.parent.removeChild(fragmentContainer)
    }
  }
}
