import React, { MutableRefObject } from "react"
import * as PIXI from "pixi.js"
import { Ball, Block } from "@/types"
import { dropAnimation } from "./dropAnimation"
import { animateFragments } from "./animateFragments"
import { animateConnection } from "./animateConnection"
import { moveBall } from "@brick/game"

interface Props {
  addBlocks: () => void
  ballRef: MutableRefObject<Ball[]>
  ballYStartPosition: number
  blockGraphicsRef: MutableRefObject<PIXI.Graphics | null>
  blocks: Block[]
  drawBalls: () => void
  drawBlocks: () => void
  isShootingRef: MutableRefObject<boolean>
  previousBalls: number
  scale: number
  setBallsLength: React.Dispatch<React.SetStateAction<number>>
  setBallsOptions: React.Dispatch<
    React.SetStateAction<{ left: number; pushed: boolean }>
  >
  setPreviousBalls: React.Dispatch<React.SetStateAction<number>>
  frameCountRef: MutableRefObject<number>
  setScore: React.Dispatch<React.SetStateAction<number>>
  drawBlocksText: () => void
}

export const moveGameBalls = ({
  ballRef,
  blocks,
  setBallsOptions,
  scale,
  setScore,
  addBlocks,
  drawBalls,
  ballYStartPosition,
  setBallsLength,
  blockGraphicsRef,
  drawBlocks,
  isShootingRef,
  previousBalls,
  setPreviousBalls,
  frameCountRef,
  drawBlocksText,
}: Props) => {
  let animationId: number | null = null
  const animate = () => {
    frameCountRef.current++
    if (!isShootingRef.current && animationId !== null) {
      cancelAnimationFrame(animationId)
      animationId = null
      return
    }

    moveBall(
      ballRef.current,
      blocks,
      frameCountRef.current,
      scale,
      "front",
      (block: Block) => {
        dropAnimation({
          block,
          ballYStartPosition,
          setBallsLength,
          ballRef,
          scale,
          drawBlocks,
          blockGraphicsRef,
        })
      },
      (block: Block) =>
        animateFragments({
          block,
          scale,
          blockGraphicsRef: blockGraphicsRef.current!,
        }),
      () => {
        drawBlocksText(), drawBlocks()
      },
      () => {
        frameCountRef.current = 0
        isShootingRef.current = false
        const { promise } = animateConnection({ ballRef, drawBalls })
        promise.then(() => {
          setBallsOptions((prev) => ({
            ...prev,
            left: ballRef.current[0].x,
            pushed: false,
          }))
        })
        stopAnimation()
      }
    )
    drawBalls()
    animationId = requestAnimationFrame(animate)
    return false
  }

  // Начать анимацию, если pushed === true
  const startAnimation = () => {
    if (isShootingRef.current && animationId === null)
      animationId = requestAnimationFrame(animate)
  }

  // Остановить анимацию
  const stopAnimation = () => {
    if (animationId !== null) {
      addBlocks()
      setScore((prev) => prev + 1)

      if (previousBalls !== 0) {
        ballRef.current = ballRef.current.slice(
          0,
          ballRef.current.length - previousBalls
        )
        setBallsLength((prev) => prev - previousBalls)
        setPreviousBalls(0)
      }
      cancelAnimationFrame(animationId)
      animationId = null
    }
  }

  return {
    animate,
    startAnimation,
    stopAnimation,
  }
}
