import { buyBoost } from "@/api/boosts"
import Menu from "@/components/common/Menu"
import Boosts from "@/components/pages/Boosts"
import { useAdsgram } from "@/hooks/useAdgram"
import useBackButton from "@/hooks/useBackButton"
import { useBoosts } from "@/hooks/useBoosts"
import { useToken } from "@/hooks/useToken"
import { useUser } from "@/hooks/useUser"
import { useRef, useState } from "react"

interface Booster {
  image: string
  price: number
  subTitle: string
  title: string
  value: string
}

const BoostsPage = () => {
  const { gameToken } = useToken()
  const { setBoosts } = useBoosts()
  const { user, updateUserBalance } = useUser()

  const [helpIsOpened, setHelpIsOpened] = useState(false)
  const boostObjectRef = useRef<Booster>({
    image: "",
    title: "",
    subTitle: "",
    value: "",
    price: 0,
  })
  const [balanceIsOpened, setBalanceIsOpened] = useState(false)
  const [notEnoughOpened, setNotEnoughOpened] = useState(false)
  const [buyIsOpened, setBuyIsOpened] = useState(false)
  const [adStatus, setAdStatus] = useState<"success" | "null" | "error">("null")
  const [buyError, setBuyError] = useState(false)

  const buyBoosts = async () => {
    try {
      if (boostObjectRef.current.price < user.balance) {
        const data = await buyBoost({
          token: gameToken,
          currency: "balance",
          kind: "boosts",
          value: boostObjectRef.current.value,
          byAd: false,
        })
        setBoosts(gameToken)
        updateUserBalance(user.id, gameToken)
        if (data.code === "error") {
          setBuyError(true)
          setBuyIsOpened(false)
        } else setBuyError(false)
      } else {
        setBuyIsOpened(false)
        setNotEnoughOpened(true)
      }
    } catch (error) {
      setBuyIsOpened(false)
      setBuyError(true)
    }
  }

  const buyBoostsByAd = async () => {
    try {
      const data = await buyBoost({
        token: gameToken,
        currency: "ad",
        kind: "boosts",
        value: boostObjectRef.current.value,
        byAd: true,
      })
      setBoosts(gameToken)
      updateUserBalance(user.id, gameToken)

      if (data.code === "error") {
        setBuyError(true)
        setBuyIsOpened(false)
      } else {
        setAdStatus("success")
        setBuyError(false)
      }
    } catch (error) {
      setBuyIsOpened(false)
      setBuyError(true)
    }
  }

  const showAd = useAdsgram({
    onReward: () => buyBoostsByAd(),
    onError: () => {
      setAdStatus("error")
    },
  })

  useBackButton()

  return (
    <>
      <Boosts
        helpIsOpened={helpIsOpened}
        setHelpIsOpened={setHelpIsOpened}
        boostObjectRef={boostObjectRef}
        balanceIsOpened={balanceIsOpened}
        setBalanceIsOpened={setBalanceIsOpened}
        buyIsOpened={buyIsOpened}
        setBuyIsOpened={setBuyIsOpened}
        adStatus={adStatus}
        setAdStatus={setAdStatus}
        showAd={showAd}
        buyError={buyError}
        setBuyError={setBuyError}
        buyBoosts={buyBoosts}
        setNotEnoughOpened={setNotEnoughOpened}
        notEnoughOpened={notEnoughOpened}
      />
      <Menu />
    </>
  )
}

export default BoostsPage
