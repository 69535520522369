import CardWrapper from "@/components/common/CardWrapper"
import CommonHeader from "@/components/common/CommonHeader"
import SubHeader from "@/components/common/SubHeader"
import { useTranslation } from "react-i18next"
import CommonModal from "@/components/common/CommonModal"
import { useUser } from "@/hooks/useUser"
import { formatNumber } from "@/utils/formatNumber"
import { useBoosts } from "@/hooks/useBoosts"
import { MutableRefObject } from "react"

interface Booster {
  image: string
  price: number
  subTitle: string
  title: string
  value: string
}

interface Props {
  adStatus: "success" | "null" | "error"
  balanceIsOpened: boolean
  boostObjectRef: MutableRefObject<Booster>
  buyBoosts: () => void
  buyError: boolean
  buyIsOpened: boolean
  helpIsOpened: boolean
  notEnoughOpened: boolean
  setAdStatus: React.Dispatch<
    React.SetStateAction<"success" | "null" | "error">
  >
  setBalanceIsOpened: React.Dispatch<React.SetStateAction<boolean>>
  setBuyError: React.Dispatch<React.SetStateAction<boolean>>
  setBuyIsOpened: React.Dispatch<React.SetStateAction<boolean>>
  setHelpIsOpened: React.Dispatch<React.SetStateAction<boolean>>
  setNotEnoughOpened: React.Dispatch<React.SetStateAction<boolean>>
  showAd: () => void
}

const Boosts = ({
  helpIsOpened,
  setHelpIsOpened,
  boostObjectRef,
  balanceIsOpened,
  setBalanceIsOpened,
  buyIsOpened,
  setBuyIsOpened,
  adStatus,
  setAdStatus,
  showAd,
  buyError,
  setBuyError,
  buyBoosts,
  setNotEnoughOpened,
  notEnoughOpened,
}: Props) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { boosts } = useBoosts()

  return (
    <div className="w-full flex-col px-3">
      <div className="flex w-full items-center justify-end pt-3">
        <div className="bg-light-gray/50 flex items-center justify-center rounded-full px-6 py-2">
          <p className="font-sfUiSemibold mr-1 text-base text-black dark:text-white">
            {formatNumber(user.balance, 0).fullNumber}
          </p>
          <div className="flex items-center gap-x-3">
            <img src="/svg/coin.svg" />
            <img
              onClick={() => setBalanceIsOpened(true)}
              width={16}
              height={16}
              src="/svg/questionMark.svg"
            />
          </div>
        </div>
      </div>
      <CommonHeader title={t("boostsPage.myBoosts")} styles="mb-4" />
      <div className="mb-3 flex w-full items-center justify-between">
        {boosts.map((boost) => (
          <div
            key={boost.name}
            className="dark:bg-dark-canvas border-text-gray relative rounded-xl border border-[1px] bg-white px-9 py-5"
          >
            <img width={35} height={35} src={`/images/${boost.name}.png`} />
            <p className="font-sfUiRegular">x{boost.quantity}</p>
            <img
              onClick={() => {
                setHelpIsOpened(true)
                boostObjectRef.current = {
                  image: `/images/${boost.name}Big.png`,
                  title: t("boostsPage." + boost.name),
                  subTitle: t("boostsPage." + boost.name + "Boost"),
                  value: boost.name,
                  price: boost.price,
                }
              }}
              className="absolute right-2 top-2"
              width={12}
              height={12}
              src="/images/question.png"
            />
          </div>
        ))}
      </div>
      <CommonHeader title={t("boostsPage.market")} styles="mb-4" />
      <div>
        {boosts.map((boost) => (
          <CardWrapper
            bgColor={{ dark: "bg-dark-canvas", light: "white" }}
            key={boost.name}
            image={`/images/${boost.name}.png`}
            styles="p-2 rounded-xl mb-3"
            imageStyle="w-10 h-10"
            square
            size={10}
            leftSide={
              <div className="ml-2 flex-col text-left">
                <h2 className="text-gray font-sfUiSemibold text-base">
                  {t("boostsPage." + boost.name)}
                </h2>
                <p className="font-sfUiRegular">x{boost.quantity}</p>
              </div>
            }
            rightSide={
              <div className="flex items-center justify-between">
                <button
                  onClick={() => {
                    boostObjectRef.current = {
                      image: `/images/${boost.name}Big.png`,
                      title: t("boostsPage." + boost.name),
                      subTitle: t("boostsPage." + boost.name + "Boost"),
                      value: boost.name,
                      price: boost.price,
                    }
                    if (user.balance > boost.price) {
                      setBuyIsOpened(true)
                    } else {
                      setNotEnoughOpened(true)
                    }
                  }}
                  className="bg-button-color disabled:bg-gray flex items-center justify-center rounded-full px-8 py-3 text-white disabled:opacity-50"
                >
                  <img src="/svg/coin.svg" />
                  <p className="ml-2">{boost.price}</p>
                </button>
                <div className="bg-text-gray ml-2 mr-2 h-[50px] w-[1px]" />
                <button
                  onClick={() => {
                    boostObjectRef.current = {
                      image: `/images/${boost.name}Big.png`,
                      title: t("boostsPage." + boost.name),
                      subTitle: t("boostsPage." + boost.name + "Boost"),
                      value: boost.name,
                      price: boost.price,
                    }
                    showAd()
                  }}
                  className="bg-purple disabled:bg-gray/50 flex h-[45px] w-[49px] items-center justify-center rounded-full disabled:opacity-50"
                >
                  <img
                    className="mx-6 my-5 h-[16px] w-[20px]"
                    width={20}
                    height={20}
                    src="/images/horn.png"
                  />
                </button>
              </div>
            }
          />
        ))}
      </div>
      <SubHeader
        styles="text-center mt-2"
        title={t("boostsPage.getBoosters")}
      />
      <CommonModal
        showModal={helpIsOpened}
        setShowModal={setHelpIsOpened}
        buttonContent={
          <div className={`flex w-full items-center justify-center`}>
            <button
              onClick={() => setHelpIsOpened(false)}
              className="bg-button-color w-full rounded-full py-2.5 text-white"
            >
              {t("others.ok")}
            </button>
          </div>
        }
        content={
          <div className="w-full flex-col items-center justify-center">
            <img
              className="m-auto mb-5 h-[75px] w-[75px]"
              width={75}
              height={75}
              src={boostObjectRef.current.image}
            />
            <div className="mb-3 flex justify-center">
              <h2 className="font-sfUiBold text-center text-2xl text-black dark:text-white">
                {boostObjectRef.current.title}
              </h2>
            </div>
            <SubHeader
              styles="text-center text-sm mb-4"
              title={boostObjectRef.current.subTitle}
            />
          </div>
        }
      />
      <CommonModal
        showModal={balanceIsOpened}
        setShowModal={setBalanceIsOpened}
        buttonContent={
          <div className={`flex w-full items-center justify-center`}>
            <button
              onClick={() => setBalanceIsOpened(false)}
              className="bg-button-color w-full rounded-full py-2.5 text-white"
            >
              {t("others.ok")}
            </button>
          </div>
        }
        content={
          <div className="w-full flex-col items-center justify-center">
            <img
              className="m-auto mb-5 h-[75px] w-[75px]"
              width={75}
              height={75}
              src={"/images/gameBig.png"}
            />
            <div className="mb-3 flex justify-center">
              <h2 className="font-sfUiBold text-center text-2xl text-black dark:text-white">
                {t("boostsPage.playAndCollectCoins")}
              </h2>
            </div>
            <SubHeader
              styles="text-center text-sm mb-4"
              title={t("boostsPage.playGameAndCompleteTasksToEarnCoins")}
            />
          </div>
        }
      />
      <CommonModal
        showModal={buyIsOpened}
        setShowModal={setBuyIsOpened}
        buttonContent={
          <div className={`flex w-full items-center justify-between gap-x-3`}>
            <button
              onClick={() => {
                buyBoosts()
                setBuyIsOpened(false)
              }}
              className="bg-button-color w-full rounded-full py-2.5 text-white"
            >
              {t("others.yes")}
            </button>
            <button
              onClick={() => {
                setBuyIsOpened(false)
              }}
              className="bg-button-color w-full rounded-full py-2.5 text-white"
            >
              {t("others.no")}
            </button>
          </div>
        }
        content={
          <div className="w-full flex-col items-center justify-center">
            <img
              className="m-auto mb-5 h-[75px] w-[75px]"
              width={75}
              height={75}
              src={boostObjectRef.current.image}
            />
            <div className="mb-3 flex justify-center">
              <h2 className="font-sfUiBold text-center text-2xl text-black dark:text-white">
                {t("boostsPage.confirmPurchase")}
              </h2>
            </div>
            <SubHeader
              styles="text-center text-sm mb-4"
              title={t("boostsPage.confirmationText")}
            />
          </div>
        }
      />
      <CommonModal
        showModal={adStatus !== "null"}
        setShowModal={setHelpIsOpened}
        buttonContent={
          <div className={`flex w-full items-center justify-center`}>
            <button
              onClick={() => setAdStatus("null")}
              className="bg-button-color w-full rounded-full py-2.5 text-white"
            >
              {t("others.ok")}
            </button>
          </div>
        }
        content={
          <div className="w-full flex-col items-center justify-center">
            {adStatus === "success" && (
              <img
                className="m-auto mb-5 h-[75px] w-[75px]"
                width={75}
                height={75}
                src={boostObjectRef.current.image}
              />
            )}
            <div className="mb-3 flex justify-center">
              <h2 className="font-sfUiBold text-center text-2xl text-black dark:text-white">
                {adStatus === "success"
                  ? t("boostsPage.boosterReceived")
                  : t("boostsPage.adNotAvailable")}
              </h2>
            </div>
            <SubHeader
              styles="text-center text-sm mb-4"
              title={
                adStatus === "success"
                  ? t("boostsPage.thxForAd")
                  : t("boostsPage.adAllWatched")
              }
            />
          </div>
        }
      />
      <CommonModal
        buttonContent={
          <div className="flex w-full items-center justify-center">
            <button
              onClick={() => setNotEnoughOpened(false)}
              className="bg-button-color flex items-center justify-center rounded-full px-12 py-3 text-white"
            >
              <p className="ml-2">Ok</p>
            </button>
          </div>
        }
        showModal={notEnoughOpened}
        setShowModal={setNotEnoughOpened}
        content={
          <>
            <h2 className="font-sfUiBold mb-3 text-center text-xl text-black dark:text-white">
              {t("boostsPage.notEnoughCoins")}
            </h2>
            <SubHeader
              styles="text-center mb-5 text-sm"
              title={t("boostsPage.notEnoughForBuy")}
            />
          </>
        }
      />
      <CommonModal
        showModal={buyError}
        setShowModal={setBuyError}
        buttonContent={
          <div className={`flex w-full items-center justify-center`}>
            <button
              onClick={() => setBuyError(false)}
              className="bg-button-color w-full rounded-full py-2.5 text-white"
            >
              {t("others.ok")}
            </button>
          </div>
        }
        content={
          <SubHeader
            styles="text-center text-sm mb-4"
            title={t("errors.error")}
          />
        }
      />
    </div>
  )
}

export default Boosts
